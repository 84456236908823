import image1 from "../../../images/originals/RESIDENCIAL/CASACENTREHISTORIC/1.webp";
import image2 from "../../../images/originals/RESIDENCIAL/CASACENTREHISTORIC/2.webp";
import image3 from "../../../images/originals/RESIDENCIAL/CASACENTREHISTORIC/3.webp";
import image4 from "../../../images/originals/RESIDENCIAL/CASACENTREHISTORIC/4.webp";
import image6 from "../../../images/originals/RESIDENCIAL/CASACENTREHISTORIC/6.webp";
import image7 from "../../../images/originals/RESIDENCIAL/CASACENTREHISTORIC/7.webp";
import image8 from "../../../images/originals/RESIDENCIAL/CASACENTREHISTORIC/8.webp";
import image9 from "../../../images/originals/RESIDENCIAL/CASACENTREHISTORIC/9.webp";
import image10 from "../../../images/originals/RESIDENCIAL/CASACENTREHISTORIC/10.webp";

const images = [
  image1,
  image2,
  image3,
  image4,
  image6,
  image7,
  image8,
  image9,
  image10,
];

export { images };
