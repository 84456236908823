import { useContext, useEffect } from 'react';
import { NavigationBarContext } from '../../components/navigationbar/NavigationBarContext';

function PoliticaDeCookies(){
    const {secondLevelNav, setSecondLevelNav} = useContext(NavigationBarContext);
    useEffect(() => {
        setSecondLevelNav(false);    
    }, [])

    return (<div className='m-7'>
                    POLÍTICA DE COOKIES <br/>
            SEGUNDA CAPA POLÍTICA DE COOKIES <br/>
            ¿QUÉ ES UNA COOKIE? <br/>
            Este sitio web utiliza cookies y/o tecnologías similares que almacenan y recuperan información cuando navegas. En general, estas tecnologías pueden servir para finalidades muy diversas, como, por ejemplo, reconocerte como usuario, obtener información sobre tus hábitos de navegación o personalizas la forma en la que se muestra el contenido. Los usos concretos que hacemos de estas tecnologías se describen a continuación.  <br/>
            ¿QUIÉN UTILIZA LA INFORMACIÓN DE LAS COOKIES? <br/> <br/>
            <table className="border-solid border-black border-2">
                <tr>
                    <th> Nombre de la empresa </th>
                    <th> ALBERTO MILLA, S.L. </th>
                </tr>
                <tr>
                    <th> NIF </th>
                    <th> B97414007 </th>
                </tr>
                <tr> 
                    <th> Direccion </th>
                    <th> Calle Peset Aleixandre 45 - Silla 46460 Valencia </th>
                </tr>
            </table>	<br/>
            
            ¿QUÉ TIPOS DE COOKIES EXISTEN?<br/>
            Las Cookies pueden clasificarse, en virtud de sus características propias, dentro de diversas categorías.<br/>
            1.	Según la entidad que la gestione:<br/>
            •	Cookies propias: Son aquellas que se envían a tu ordenador y son gestionadas exclusivamente por nosotros para el mejor funcionamiento del Sitio Web.<br/>
            •	Cookies de terceros: Son aquellas que se envían a tu ordenador y no son gestionadas por nosotros sino por una tercera entidad. <br/>
            2.	Según el plazo de tiempo que permanecen activadas: <br/>
            •	Cookies de sesión: Este tipo de cookies recaban y almacenan datos únicamente mientras el Usuario accede al Sitio Web. <br/>
            •	Cookies persistentes: Mediante este tipo de cookies los datos siguen almacenados y pueden ser accedidos y tratados durante el periodo definido por el responsable de la cookie, y puede ir de unos minutos a varios años.<br/>
            
            3.	Según la finalidad:<br/>
            •	Cookies técnicas: Son aquellas que permiten al usuario la navegación a través del sitio web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que el mismo ofrece, como por ejemplo, controlar  el  tráfico  y  la comunicación   de   datos,   identificar   la   sesión,   acceder   a   partes   de   acceso restringido, recordar los elementos que integran un pedido,  realizar el proceso de compra  de  un  pedido,  realizar  la  solicitud  de  inscripción  o  participación  en  un evento,   utilizar   elementos   de   seguridad   durante   la   navegación,   almacenar contenidos para la difusión de videos o sonido o compartir contenidos a través de redes sociales.<br/>
            •	Cookies de personalización: Son aquéllas que permiten al usuario accede al servicio con algunas características de carácter general predefinidas, como por ejemplo el idioma, la configuración regional desde donde accede al servicio, etc. <br/>
            •	Cookies de análisis: Son aquéllas que permiten al responsable de las mismas, el seguimiento y  análisis del comportamiento de los usuarios de los sitios web a los que  están  vinculadas.  La  información  recogida  mediante  este  tipo  de  cookies  se utiliza en la medición de la actividad de los sitios web, aplicación o plataforma y para  la  elaboración  de  perfiles  de  navegación  de  los  usuarios  de  dichos  sitios, aplicaciones y plataformas, con el fin de introducir mejoras en función del análisis de los datos de uso que hacen los usuarios del servicio.<br/>
            •	Cookies publicitarias: Son aquéllas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso, se haya incluido en el sitio web, aplicación o plataforma desde la que presta el servicio solicitado en base a  los criterios como el contenido editado o la frecuencia en la que se muestran los anuncios.<br/>
            •	Cookies de publicidad comportamental: Son aquellas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido en su página web, aplicación o plataforma desde la que presta el servicio solicitado. Estas cookies almacenan información del comportamiento de los usuarios obtenida a través de la observación continuada de sus hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar publicidad en función del mismo.<br/>
            
            ¿QUÉ TIPOS DE COOKIES UTILIZA ESTE SITIO WEB?<br/>
            A continuación, se especifican las cookies en concreto empleadas en el presente Sitio Web:<br/>
            Nombre	Tipo (técnicas, personalización, análisis o publicitarias)	Duración	Finalidad	Propias/Terceros <br/>
            *Identificación del tercero (link a la Política de Cookies del tercero)<br/>
                            
            Esta tabla puede ser actualizada conforme a las cookies empleadas en cada momento. Te aconsejamos que consulte nuestra Política de Cookies de forma regular. <br/>
            
            
            ¿SE REALIZAN TRANFERENCIAS DE DATOS A TERCEROS PAÍSES?<br/>
            Puedes informarte de las transferencias a terceros países que, en su caso, realizan los terceros identificados en esta política de cookies en sus correspondientes políticas (Ver los enlaces facilitados en la tabla anterior, en el apartado de cookies de terceros).<br/>
            ¿CÓMO DESHABILITAR Y BLOQUAR COOKIES? <br/>
            Te informamos que, en cualquier caso, puedes deshabilitar o bloquear las cookies activando la configuración de tu navegador, permitiéndote rechazar la instalación de todas o algunas de las cookies. En caso de no permitir la instalación de cookies es posible que no puedas acceder a algunas de las funcionalidades del presente sitio web, y por tanto, tu experiencia como usuario en el sitio web resulte menos satisfactoria.<br/>
            Puedes permitir, bloquear o eliminar las cookies instaladas en tu equipo mediante la configuración de las opciones del navegador instalado en tu ordenador. Para más información sobre cómo ajustar sus configuraciones de cookies en los siguientes navegadores, te remitimos al enlace pertinente:<br/>
            Internet Explorer<br/>
            <a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies</a> <br/>
            Firefox<br/>
            <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we">https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we</a> <br/>
            Chrome<br/>
            <a href="http://support.google.com/chrome/bin/answer.py?hl=en&answer=9564">http://support.google.com/chrome/bin/answer.py?hl=en&answer=9564</a> <br/>
            Safari<br/>
            <a href="https://support.apple.com/kb/PH19214?viewlocale=es_ES&locale=es_ES">https://support.apple.com/kb/PH19214?viewlocale=es_ES&locale=es_ES</a> <br/>
            Opera<br/>
            <a href="http://help.opera.com/Windows/11.50/es-ES/cookies.html">http://help.opera.com/Windows/11.50/es-ES/cookies.html</a> <br/>
            ¿QUÉ DERECHOS TIENES SOBRE LOS DATOS PERSONALES APORTADOS?<br/>
            Como usuario, puedes ejercitar los siguientes derechos: <br/>
            •Derecho de acceso: Tienes derecho a obtener por parte de la empresa confirmación de si se están tratando o no tus datos personales que, y en su caso, qué datos trata.<br/>
            •Derecho de rectificación: Tienes derecho a obtener por parte de la empresa,  la rectificación de los datos personales inexactos que te conciernen. En la solicitud de rectificación deberás indicar los datos que deseas que se modifiquen.<br/>
            •Derecho de oposición: Tienes derecho a oponerte en cualquier momento, por motivos relacionados con tu situación particular, a que datos personales que te conciernan sean objeto de un tratamiento basado en el interés legítimo de la empresa. En ese caso, la empresa dejará de tratar los datos personales, salvo que acreditemos motivos legítimos imperiosos para el tratamiento  que prevalezcan sobre tus intereses, derechos y libertades, o para la formulación, el ejercicio o la defensa de reclamaciones.<br/>
            •Derecho de supresión: Tienes derecho a obtener por parte de la empresa, la supresión de los datos personales que te conciernan.<br/>
            •Derecho a la portabilidad de los datos: Tienes derecho a que la empresa transfiera tus datos personales a otro responsable del tratamiento. Solo es posible ejercer este derecho cuando el tratamiento esté basado en la ejecución de un contrato o en tu consentimiento y el tratamiento se realice por medios automatizados.<br/>
            •Derecho a la limitación del tratamiento: Tienes derecho a solicitarnos que suspendamos el tratamiento de tus datos cuando: impugnes la exactitud de los datos, mientras la empresa verifica dicha exactitud; hayas ejercido el derecho de oposición al tratamiento de tus datos, mientras que se verifica si los motivos legítimos de la empresa prevalecen sobre los tuyos como interesado.<br/>
            •Derecho a no ser objeto de decisiones individuales automatizadas, incluida la elaboración de perfiles: Tienes derecho a no ser objeto de una decisión basada únicamente en el tratamiento automatizado, incluida la elaboración de perfiles, que produzca efectos jurídicos o te afecte significativamente de modo similar.<br/>
            En cualquier momento, podrás ejercitar estos Derechos, mediante un escrito y acreditando Tu personalidad dirigido al responsable del tratamiento, a la dirección que aparece más arriba.<br/>
            Además, podrás presentar una reclamación ante la autoridad de control, en este caso, la Agencia Española de Protección de Datos, si consideras que el tratamiento de datos personales infringe la legislación aplicable.<br/>

            <br/><br/><br/>
            POLÍTICA DE COOKIES EN INGLÉS<br/>
            WHAT IS A COOKIE?<br/>
            This website uses cookies and/or similar technologies that store and retrieve information when you browse. This technologies have very different purposes, such as, recognizing you as a user, obtaining information about your browsing habits or customizing the way in which the content is displayed. The specific uses we make of this technologies are described below.<br/>
            WHO USES THE INFORMATION?<br/><br/>
            <table className="border-solid border-black border-2">
                <tr>
                    <th> Name of the company </th>
                    <th> ALBERTO MILLA, S.L. </th>
                </tr>
                <tr>
                    <th> TAX ID </th>
                    <th> B97414007 </th>
                </tr>
                <tr> 
                    <th> Address </th>
                    <th> Calle Peset Aleixandre 45 - Silla 46460 Valencia </th>
                </tr>
            </table>	<br/>
            
            WHAT ARE THE DIFFERENT KINDS OF COOKIES?<br/>
            Cookies can be classify in different categories.<br/>
            1.	Depending on the entity that is managing the cookies:<br/>
            •	Own Cookies: This cookies are sent to your computer and managed exclusively by us for the correct functioning of the website.<br/>
            •	Third-Party Cookies: This kind of cookies are sent to your compute and are not managed by us. <br/>
            
            2.	Depending on the period that they remain activated:<br/>
            •	Session Cookies: This type of cookie collects and stores information only while the user is accessing a website. <br/>
            •	Persistent Cookies: Through this type of cookie the data is still stored and can be accessed and processed during the period defined by the company responsible of the cookie, and can range from few minutes to several years.<br/>
            
            3.	Depending on their purpose:<br/>
            •	Technical Cookies: This kind of cookie allows the user to navigate through the website and use the different options or services that it offers, such as, controlling traffic and data communication, identifying the session, accessing restricted parts, remember the elements that make up an order, carry out the purchase process of an order, make the request for registration or participation in an event, use security elements while browsing, store content for the broadcast of videos or sound or share content through social networks.<br/>
            •	Personalization Cookies: This type of cookie allows the user to access the service with some predetermined general characteristics, such as, language, regional settings from where you access the service, etc. <br/>
            •	Analysis Cookies: This kind of cookies allow to monitor and analyze users´ behavior in the website. The information collected through this type of cookies is used for the measurement of the activity of the website and for the elaboration of profiles, in order to introduce improvements depending on the analysis of the users´ usage.<br/>
            •	Advertising cookies: This type of cookie allows the management, in the most efficient manner, of the advertising spaces that, where appropriate, have been included in the website, application or platform from which the requested service is provided based on criteria such as content edited or how often ads are shown.<br/>
            •	Behavioral advertising cookies. This kind of cookies allow the management, in the most efficient way possible, of the advertising spaces that, where appropriate, the editor has included in its website, application or platform from which it provides<br/>
            WHICH KIND OF COOKIES DOES THE WEBSITE USE?<br/>
            Hereinafter, we specify the cookies used in this website: <br/>
            
            Name	Type	Duration	Purpose	Own/Third parties
            *Third parties identification: (link to their Cookies Policy)
            This table might be updated depending on the cookies that we use in different moments. We recommend you to check our Cookies Policy periodically. <br/>
            DO WE TRANSFER YOUR PERSONAL DATA TO THIRD COUNTRIES?<br/>
            You can get additional information about the international transfers carried out by the companies identified above, by taking a look to their corresponding policies. (You can find the links above, in the third parties cookies section)<br/>
            HOW TO DISABLE AND BLOCK A COOKIE<br/>
            We inform you that, in any case, you can disable or block the cookies by activating the configuration in your browser, allowing you to deny the installation of all or some of the cookies. If you deny the installation of a cookie, it is possible that you won´t be able to access some of the functionalities of the present website, so that, your experience as a user won´t be the same. <br/>
            You can allow, block or delete the cookies installed in your device by configuring the options of the browser installed in your electronic device. For more information about how to adjust the cookies´ configuration in each browser, you can access to the following links: <br/>
            
            Internet Explorer<br/>
            <a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies</a> <br/>
            Firefox<br/>
            <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we">https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we</a><br/>
            Chrome<br/>
            <a href="http://support.google.com/chrome/bin/answer.py?hl=en&answer=95647">http://support.google.com/chrome/bin/answer.py?hl=en&answer=95647</a><br/>
            Safari<br/>
            <a href="https://support.apple.com/kb/PH19214?viewlocale=es_ES&locale=es_ES">https://support.apple.com/kb/PH19214?viewlocale=es_ES&locale=es_ES</a><br/>
            Opera<br/>
            <a href="http://help.opera.com/Windows/11.50/es-ES/cookies.html">http://help.opera.com/Windows/11.50/es-ES/cookies.html</a><br/>
            
            WHAT RIGHTS DO YOU HAVE OVER THE PERSONAL DATA PROVIDED?<br/>
            As a user, you can exercise the following rights:<br/>
            •Right of access: You have the right to obtain from the company confirmation as to whether o not personal data concerning you are being processed, and, where that is the case, access to the personal data.<br/>
            •Right to rectification: You have the right to obtain from the company without undue delay the rectification of inaccurate personal data concerning you. In the rectification application you should specify the personal data you wish to rectify.<br/>
            •Right to object: You have the right to object, on grounds relating to your particular situation, at any time to processing of personal data concerning you which is based on the legitimate interest. The company shall no longer process the personal data unless it demonstrates compelling legitimate grounds for the processing which override the interest, rights and freedoms of the data subject or for the or the establishment, exercise or defence of legal claims.<br/>
            •Right to erasure: You have the right to obtain from the company the erasure of personal data concerning you.<br/>
            •Right to data portability: You have the right to receive your personal data, which you have provided to the company, in a structured, commonly used and machine- readable format and have the right to transmit those data to another controller without hindrance from the controller to which the personal data have been provided. <br/>
            •Right to restriction of processing: You have the right to obtain from the company restriction of processing where: the accuracy of the personal data is contested by the data subject; the processing is unlawful and the data subject opposes the erasure of the personal data and request the restriction of their use instead; the controller no longer needs the personal data for the purposes of the processing, but they are required by the data subject for the establishment, exercise or defense of legal claims.<br/>
            You can exercise your rights by sending a request to the following address Calle Peset Aleixandre 45 - Silla 46460 Valencia. <br/>
            Likewise, we inform you that if you are not pleased with our answer, you can fill a complaint in front of the competent authority (Agencia Española de Protección de Datos).<br/>

    </div>)
}

export default PoliticaDeCookies;