import image1 from "../../../images/originals/RESIDENCIAL/CASAALBEREDA/1.webp";
import image2 from "../../../images/originals/RESIDENCIAL/CASAALBEREDA/2.webp";
import image3 from "../../../images/originals/RESIDENCIAL/CASAALBEREDA/3.webp";
import image4 from "../../../images/originals/RESIDENCIAL/CASAALBEREDA/4.webp";
import image5 from "../../../images/originals/RESIDENCIAL/CASAALBEREDA/5.webp";
import image6 from "../../../images/originals/RESIDENCIAL/CASAALBEREDA/6.webp";
import image7 from "../../../images/originals/RESIDENCIAL/CASAALBEREDA/7.webp";
import image8 from "../../../images/originals/RESIDENCIAL/CASAALBEREDA/8.webp";
import image9 from "../../../images/originals/RESIDENCIAL/CASAALBEREDA/9.webp";

const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
];

export { images };
