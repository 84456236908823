import image1 from "../../../images/originals/RESIDENCIAL/VILLACARMEN/1.webp";
import image2 from "../../../images/originals/RESIDENCIAL/VILLACARMEN/2.webp";
import image4 from "../../../images/originals/RESIDENCIAL/VILLACARMEN/4.webp";
import image5 from "../../../images/originals/RESIDENCIAL/VILLACARMEN/5.webp";
import image6 from "../../../images/originals/RESIDENCIAL/VILLACARMEN/6.webp";
import image7 from "../../../images/originals/RESIDENCIAL/VILLACARMEN/7.webp";

const images = [image1, image2, image4, image5, image6, image7];

export { images };
