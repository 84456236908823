import image1 from "../../../images/originals/EQUIPAMIENTOPUBLICO/POLIDEPORTIVOXIRIVELLA/1.webp";
import image2 from "../../../images/originals/EQUIPAMIENTOPUBLICO/POLIDEPORTIVOXIRIVELLA/2.webp";
import image3 from "../../../images/originals/EQUIPAMIENTOPUBLICO/POLIDEPORTIVOXIRIVELLA/3.webp";
import image4 from "../../../images/originals/EQUIPAMIENTOPUBLICO/POLIDEPORTIVOXIRIVELLA/4.webp";
import image5 from "../../../images/originals/EQUIPAMIENTOPUBLICO/POLIDEPORTIVOXIRIVELLA/5.webp";
import image7 from "../../../images/originals/EQUIPAMIENTOPUBLICO/POLIDEPORTIVOXIRIVELLA/7.webp";
import image8 from "../../../images/originals/EQUIPAMIENTOPUBLICO/POLIDEPORTIVOXIRIVELLA/8.webp";

const images = [image1, image2, image3, image4, image5, image7, image8];

export { images };
