import image1 from "../../../images/originals/RESIDENCIAL/CASALABEGA/1.webp";
import image2 from "../../../images/originals/RESIDENCIAL/CASALABEGA/2.webp";
import image3 from "../../../images/originals/RESIDENCIAL/CASALABEGA/3.webp";
import image4 from "../../../images/originals/RESIDENCIAL/CASALABEGA/4.webp";
import image5 from "../../../images/originals/RESIDENCIAL/CASALABEGA/5.webp";
import image6 from "../../../images/originals/RESIDENCIAL/CASALABEGA/6.webp";
import image7 from "../../../images/originals/RESIDENCIAL/CASALABEGA/7.webp";
import image8 from "../../../images/originals/RESIDENCIAL/CASALABEGA/8.webp";
import image9 from "../../../images/originals/RESIDENCIAL/CASALABEGA/9.webp";
import image10 from "../../../images/originals/RESIDENCIAL/CASALABEGA/91.webp";
import image11 from "../../../images/originals/RESIDENCIAL/CASALABEGA/92.webp";
import image12 from "../../../images/originals/RESIDENCIAL/CASALABEGA/93.webp";

const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
];

export { images };
