import image1 from "../../../images/originals/RESIDENCIAL/EDIFICINOUALGEMESI/1.webp";
import image2 from "../../../images/originals/RESIDENCIAL/EDIFICINOUALGEMESI/2.webp";
import image3 from "../../../images/originals/RESIDENCIAL/EDIFICINOUALGEMESI/3.webp";
import image4 from "../../../images/originals/RESIDENCIAL/EDIFICINOUALGEMESI/4.webp";
import image5 from "../../../images/originals/RESIDENCIAL/EDIFICINOUALGEMESI/5.webp";
import image6 from "../../../images/originals/RESIDENCIAL/EDIFICINOUALGEMESI/6.webp";

const images = [image1, image2, image3, image4, image5, image6];

export { images };
