import image1 from "../../../images/originals/EQUIPAMIENTOPRIVADO/JOYERIA/1.webp";
import image2 from "../../../images/originals/EQUIPAMIENTOPRIVADO/JOYERIA/2.webp";
import image3 from "../../../images/originals/EQUIPAMIENTOPRIVADO/JOYERIA/3.webp";
import image4 from "../../../images/originals/EQUIPAMIENTOPRIVADO/JOYERIA/4.webp";
import image5 from "../../../images/originals/EQUIPAMIENTOPRIVADO/JOYERIA/5.webp";
import image7 from "../../../images/originals/EQUIPAMIENTOPRIVADO/JOYERIA/7.webp";
import image8 from "../../../images/originals/EQUIPAMIENTOPRIVADO/JOYERIA/8.webp";
import image9 from "../../../images/originals/EQUIPAMIENTOPRIVADO/JOYERIA/9.webp";

const images = [image1, image2, image3, image4, image5, image7, image8, image9];

export { images };
