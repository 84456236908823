import image1 from "../../../images/originals/EQUIPAMIENTOPUBLICO/CASALJOVESILLA/1.webp";
import image2 from "../../../images/originals/EQUIPAMIENTOPUBLICO/CASALJOVESILLA/2.webp";
import image3 from "../../../images/originals/EQUIPAMIENTOPUBLICO/CASALJOVESILLA/3.webp";
import image4 from "../../../images/originals/EQUIPAMIENTOPUBLICO/CASALJOVESILLA/4.webp";
import image6 from "../../../images/originals/EQUIPAMIENTOPUBLICO/CASALJOVESILLA/6.webp";
import image7 from "../../../images/originals/EQUIPAMIENTOPUBLICO/CASALJOVESILLA/7.webp";
import image8 from "../../../images/originals/EQUIPAMIENTOPUBLICO/CASALJOVESILLA/8.webp";

const images = [image1, image2, image3, image4, image6, image7, image8];

export { images };
